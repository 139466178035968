import * as React from "react";
import Redirect from "../../../components/redirect";
//--------------------------------------------------

  const url = "https://my.matterport.com/show/?m=pV4hVB8Q5Xo";

//--------------------------------------------------
// common config below this line, no need to change
const IndexPage = () => (
  <Redirect goto_url={url} />
);

export default IndexPage;
